<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span v-if="id > 0" class="pr-2 mr-2 font-bold text-2xl"
        >Faktur Pembelian - Edit</span
      >
      <span v-else class="pr-2 mr-2 font-bold text-2xl"
        >Faktur Pembelian - Tambah</span
      >
      <div>
        <i class="pi pi-home mr-2"></i>
        <span v-if="id > 0" class="font-small"
          >/ Pembelian / Faktur Pembelian / Edit</span
        >
        <span v-else class="font-small"
          >/ Pembelian / Faktur Pembelian / Tambah</span
        >
      </div>
    </div>
    <form-purchase-invoice :id="id" />
  </div>
</template>

<script>
import FormPurchaseInvoice from '@/components/pembelian/FormPurchaseInvoice'

export default {
  components: {
    FormPurchaseInvoice,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
    }
  },
}
</script>
