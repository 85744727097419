<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_po"
          :globalFilterFields="['po_no', 'nama_obat']"
          dataKey="id"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter obat"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column field="po_no" header="PO" style="min-width: 15rem" />
          <Column field="nama_obat" header="Obat" style="min-width: 15rem" />
          <Column field="harga" header="harga" style="min-width: 3rem">
            <template #body="{ data }">
              {{ formatCurrency(data.harga) }}
            </template>
          </Column>
          <Column field="quantity_actual" header="QTY" style="min-width: 3rem">
            <template #body="{ data }">
              {{ formatNumber(data.quantity_actual) }}
            </template>
          </Column>
          <Column field="satuan" header="Satuan" />
          <Column field="keterangan" header="Keterangan">

          </Column>
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        class="p-button-text mr-2"
        @click="$emit('close')"
      />
      <Button
        :disabled="disabledSave"
        label="Tambahkan"
        icon="pi pi-check"
        @click="add"
      />
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatNumber } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    purchaseOrder: {
      type: Array,
      default() {
        return []
      },
    },
    excludeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        po_no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      disabledSave: true,
    }
  },
  watch: {
    selected(val) {
      if (val && val.length > 0) {
        this.disabledSave = false
      } else {
        this.disabledSave = true
      }
    },
  },
  computed: {
    list_po() {
      let items = []
      this.purchaseOrder.forEach((po) => {
        po.obat.forEach((el) => {
          el.po_no = po.po_no
          el.golongan = po.golongan.nama
          el.po_no = po.po_no
          el.po_at = po.po_at
          // el.ppn = 0 // aktual set di header
          el.diskon = 0
          el.diskon_harga = 0
          el.harga_diskon = 0
          el.harga_total = el.harga * el.quantity
          items.push(el)
        })
      })
      const result = items.slice()
      // Tampilkan data obat hanya yang belum dipilih
      return result.filter((item) => {
        return this.excludeList.indexOf(item.id) === -1
      })
    },
  },
  methods: {
    add() {
      this.$emit('save', this.selected)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      value = value ? value : 0
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      value = value ? value : 0
      return formatNumber(value)
    },
  },
}
</script>
