<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-12">
          <label>Salesman</label>
          <Dropdown
            v-model="form.salesman"
            :options="listSalesman"
            class="w-full"
            input-class="w-full"
            optionValue="id"
            optionLabel="nama"
          />
        </div>
        <div class="field col-12 md:col-12">
          <label>Tgl Bukti</label>
          <Calendar
            v-model="form.si_at"
            dateFormat="dd/mm/yy"
            :class="{
              'p-invalid': v$.form.si_at.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.si_at.$invalid && submitted) ||
              v$.form.si_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.si_at.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>No Bukti</label>
          <InputText
            v-model="form.si_no"
            class="w-full"
            :class="{
              'p-invalid': v$.form.si_no.$invalid && submitted,
            }"
          />
          <small
            v-if="
              (v$.form.si_no.$invalid && submitted) ||
              v$.form.si_no.$pending.$response
            "
            class="p-error"
            >{{ v$.form.si_no.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Tgl Jatuh Tempo</label>
          <Calendar
            v-model="form.top_at"
            dateFormat="dd/mm/yy"
            :class="{
              'p-invalid': v$.form.top_at.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.top_at.$invalid && submitted) ||
              v$.form.top_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.top_at.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>DPP</label>
          <InputNumber
            v-model="form.dpp"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.dpp.$invalid && submitted) ||
              v$.form.dpp.$pending.$response
            "
            class="p-error"
            >{{ v$.form.dpp.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>PPN</label>
          <InputNumber
            v-model="form.ppn"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.ppn.$invalid && submitted) ||
              v$.form.ppn.$pending.$response
            "
            class="p-error"
            >{{ v$.form.ppn.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import SalesmanService from '@/services/SalesmanService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      listSalesman: [],
    }
  },
  created() {
    this.salesmanService = new SalesmanService()
  },
  mounted() {
    this.onLoadSalesman()
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    onLoadSalesman() {
      this.salesmanService
        .get()
        .then((res) => {
          this.listSalesman = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Salesman', this)
        })
    },
    onCloseForm() {
      this.$emit('close')
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        si_no: {
          required: helpers.withMessage('No Faktur harus diisi.', required),
        },
        si_at: {
          required: helpers.withMessage(
            'Tanggal Faktur harus diisi.',
            required
          ),
        },
        top_at: {
          required: helpers.withMessage(
            'Tgl Jatuh Tempo harus diisi.',
            required
          ),
        },
        dpp: {
          required: helpers.withMessage('DPP harus diisi.', required),
        },
        ppn: {
          required: helpers.withMessage('PPN harus diisi.', required),
        },
      },
    }
  },
}
</script>
