<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_items"
          :globalFilterFields="['pi_no','pr_no', 'pi_at', 'inv_no']"
          dataKey="id"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter Invoice/Retur"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column field="inv_no" header="No Invoice" style="min-width: 15rem" />
          <Column field="pr_no" header="No Retur" style="min-width: 10rem" />
          <Column field="pi_at" header="Tanggal" style="min-width: 15rem">
            <template #body="{ data }">
              <div v-if="data.inv_no">
                {{ formatDate(data.pi_at) }}
              </div>
              <div v-if="data.pr_no">
                {{ formatDate(data.pr_at) }}
              </div>
            </template>
          </Column>
          <Column field="jumlah" header="Total" style="min-width: 15rem">
            <template #body="{ data }">
              {{ formatCurrency(data.jumlah) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        class="p-button-text mr-2"
        @click="$emit('close')"
      />
      <Button
        :disabled="disabledSave"
        label="Tambahkan"
        icon="pi pi-check"
        @click="add"
      />
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    invoice: {
      type: Array,
      default() {
        return []
      },
    },
    invoiceExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
    retur: {
      type: Array,
      default() {
        return []
      },
    },
    returExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pi_no: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        pi_at: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        jumlah: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      disabledSave: true,
    }
  },
  watch: {
    selected(val) {
      if (val && val.length > 0) {
        this.disabledSave = false
      } else {
        this.disabledSave = true
      }
    },
  },
  computed: {
    list_items() {
      const result_invoice = this.invoice.slice()
      const result_retur = this.retur.slice()

      const filtered_invoice = result_invoice.filter((item) => {
        return this.invoiceExcludeList.indexOf(item.pi_no) === -1
      })
      const filtered_retur = result_retur.filter((item) => {
        return this.returExcludeList.indexOf(item.pr_no) === -1
      })

      return filtered_invoice.concat(filtered_retur)
    },
  },
  methods: {
    add() {
      this.$emit('save', this.selected)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      value = value ? value : 0
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
