<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Purchase Order</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Pembelian / Purchase Order</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Purchase Order"
          @click="$router.push({ name: 'POAdd' })"
        />
      </div>
      <grid-po
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :golongan-obat="listGolonganObat"
        @request="onRequestData"
        @print="onPrintPO"
        @edit="onEditData"
        @delete="onConfirmDeletion"
      />
    </div>
    <Dialog
      header="Hapus Purchase Order"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Purchase order <strong>{{ form.po_no }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteData"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownload"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrint"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PurchaseOrderService from '@/services/PurchaseOrderService'
import GolonganObatService from '@/services/GolonganObatService'
import GridPo from '@/components/pembelian/GridPo'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { saveAs } from 'file-saver'

export default {
  components: {
    GridPo,
    Hotkey,
  },
  data() {
    return {
      listGolonganObat: null,
      dataService: null,
      dialogHapus: false,
      dialogViewer: false,
      faktur: 0,
      pdfSource: '',
      pdfTitle: 'PDF Viewer',
      pdfFilename: '',
      isLoading: false,
      items: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'po_at',
        sortOrder: 1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new PurchaseOrderService()
  },
  async mounted() {
    this.loadGridData()

    const golonganObatService = new GolonganObatService()
    this.listGolonganObat = await golonganObatService
      .get('?sort=nama')
      .then((res) => {
        return res.data.data
      })
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Purchase Order', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onPrintPO(data) {
      this.dataService
        .print(data.id)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'purchase-order.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Print Purchase Order', this)
        })
    },
    onPrint() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onDownload() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    onEditData(data) {
      this.$router.push({ name: 'POEdit', params: { id: data.id } })
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteData() {
      this.dataService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Obat',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Obat', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'POAdd' })
      }
    },
  },
}
</script>
