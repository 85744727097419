<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
            :disabled="disabledSave"
          />
          <Button
            :loading="isLoadingInvoice"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Kontra bon / Retur"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PembayaranHutang' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <label
                  :class="{
                    'p-error': v$.form.pp_at.$invalid && submitted,
                  }"
                >
                  Tanggal</label
                >
                <Calendar
                  v-model="form.pp_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.pp_at.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.pp_at.$invalid && submitted) ||
                    v$.form.pp_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pp_at.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <label>No Bukti</label>
                <InputText v-model="form.pp_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{
                    'p-error': v$.form.supplier.$invalid && submitted,
                  }"
                >
                  Supplier
                </label>
                <AutoComplete
                  v-model="form.supplier"
                  :suggestions="acSupplier"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.supplier.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchSupplier($event)"
                  @item-select="selectSupplier"
                >
                  <template #item="slotProps">
                    <div>
                      {{ slotProps.item.kode }} - {{ slotProps.item.nama }}
                    </div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.supplier.$invalid && submitted) ||
                    v$.form.supplier.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supplier.required.$message }}</small
                >
              </div>

              <div class="field col-12 md:col-8">
                <label>Alamat</label>
                <InputText v-model="form.alamat" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Kota</label>
                <InputText v-model="form.kota" class="w-full" disabled />
              </div>
              <div class="field col-7">
                <label>Pembayaran</label>
                <div class="formgroup-inline">
                  <div class="field-radiobutton">
                    <RadioButton
                      name="tunai"
                      value="tunai"
                      v-model="pembayaran"
                      id="pembayaran-0"
                    />
                    <label for="pembayaran-0">Tunai</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton
                      name="giro"
                      value="giro"
                      v-model="pembayaran"
                      id="pembayaran-1"
                    />
                    <label for="pembayaran-1">Giro/Cek</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton
                      name="transfer"
                      value="transfer"
                      v-model="pembayaran"
                      id="pembayaran-2"
                    />
                    <label for="pembayaran-2">Transfer</label>
                  </div>
                </div>
              </div>
              <!--<div class='field col-5' v-if="form.pembayaran === 'giro'">
                <div class='field-checkbox mb-1'>
                  <Checkbox id='tgl-bayar' v-model='isTglBayar' :binary='true' />
                  <label for='tgl-bayar'>Tanggal Bayar</label>
                </div>
                <Calendar
                  v-model='form.settle_at'
                  dateFormat='dd/mm/yy'
                  class='w-full'
                  :disabled='!isTglBayar'
                />
              </div>-->
              <div
                v-if="form.pembayaran === 'tunai'"
                class="field col-12 md:col-7"
              >
                <label                   :class="{
                    'p-error': v$.form.kas_id.$invalid && submitted,
                  }">Kode kas</label>
                <Dropdown
                  v-model="form.kas_id"
                  :options="list_daftar_kas"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                >
                  <template #option="slotProps">
                    <div class="p-dropdown-kas-option">
                      <span v-if="slotProps.option.rekening">
                        {{ slotProps.option.nama }}&nbsp;({{
                          slotProps.option.rekening
                        }})
                      </span>
                      <span v-else>{{ slotProps.option.nama }}</span>
                    </div>
                  </template>
                </Dropdown>
                <small
                  v-if="
                    (v$.form.kas_id.$invalid && submitted) ||
                    v$.form.kas_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.kas_id.required.$message }}</small
                >
              </div>
              <div
                v-if="
                  form.pembayaran === 'transfer' || form.pembayaran === 'giro'
                "
                class="field col-12 md:col-7"
              >
              <label
                  :class="{
                    'p-error': v$.form.bank_id.$invalid && submitted,
                  }"
                  >Kode Bank</label
                >
                <Dropdown
                  v-model="form.bank_id"
                  :options="list_kode_bank"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                >
                  <template #option="slotProps">
                    <div class="p-dropdown-kas-option">
                      <span v-if="slotProps.option.rekening">
                        {{ slotProps.option.nama }}&nbsp;({{
                          slotProps.option.rekening
                        }})
                      </span>
                      <span v-else>{{ slotProps.option.nama }}</span>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div
                v-if="form.pembayaran === 'giro'"
                class="field col-12 md:col-7"
              >
                <label
                  :class="{
                    'p-error': v$.form.giro_no.$invalid && submitted,
                  }"
                >
                  No Giro
                </label>
                <InputText
                  :class="{
                    'p-error': v$.form.giro_no.$invalid && submitted,
                  }"
                  v-model="form.giro_no"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.giro_no.$invalid && submitted) ||
                    v$.form.giro_no.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.giro_no.required.$message }}</small
                >
              </div>
              <div
                v-if="form.pembayaran === 'giro'"
                class="field col-12 md:col-5"
              >
                <label
                  :class="{
                    'p-error': v$.form.giro_at.$invalid && submitted,
                  }"
                >
                  Tanggal Jatuh Tempo
                </label>
                <Calendar
                  v-model="form.giro_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{
                    'p-error': v$.form.giro_at.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.giro_at.$invalid && submitted) ||
                    v$.form.giro_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.giro_at.required.$message }}</small
                >
              </div>

              <div
                v-if="form.pembayaran === 'giro' && this.form.id > 0"
                class="field col-12 md:col-3"
              >
                <label>Status Giro</label>
                <Dropdown
                  v-model="form.giro_status"
                  :options="list_giro_status"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.label }}
                  </template>
                </Dropdown>
              </div>
              <div
                v-if="form.pembayaran === 'giro' && this.form.id > 0"
                class="field col-12 md:col-4"
              >
                <label>Tanggal Status Giro</label>
                <Calendar
                  v-model="form.giro_status_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div
                v-if="form.pembayaran === 'giro' && this.form.id > 0"
                class="field col-12 md:col-5"
              >
                <label> Keterangan Giro </label>
                <InputText v-model="form.giro_keterangan" class="w-full" />
              </div>

              <div class="field col-6 md:col-6">
                <label>Jumlah</label>
                <InputNumber
                  ref="jumlah"
                  v-model="jumlah"
                  :minFractionDigits="0"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  :max="jumlahHutang"
                  @input="onInputJumlah"
                />
              </div>
            </div>
          </div>
        </div>
        <grid-pembayaran-hutang-detail
          :items="form.detail"
          :loading="isLoading"
          :show-button="true"
          :cn="form.cn"
          :dn="form.dn"
          :materai="form.materai"
          @edit="onEditInvoice"
          @delete="onConfirmDeletion"
          @materai="onUpdateMaterai"
          @cn="onUpdateCn"
          @dn="onUpdateDn"
        />
      </div>
    </form>
    <Dialog
      header="Tambah Kontra Bon/Retur"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw' }"
      modal
      maximizable
    >
      <form-list-pembayaran-hutang
        ref="formListPembayaranHutang"
        :kontra="list_kontra"
        :kontra-exclude-list="list_kb_no"
        :invoice="list_invoice"
        :invoice-exclude-list="list_invoice_no"
        :retur="list_retur"
        :retur-exclude-list="list_retur_no"
        @close="onCloseFormKontra"
        @save="onSaveDetail"
      />
      <template #footer>
        <div class="flex justify-content-end align-items-end">
          <Button
            label="Tutup"
            icon="pi pi-times"
            class="p-button-text mr-2"
            @click="onCloseFormKontra"
          />
          <Button
            label="Tambahkan"
            icon="pi pi-check"
            @click="this.$refs.formListPembayaranHutang.add()"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Edit data pembayaran"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      modal
    >
      <form-edit-pembayaran :item="formEdit" @save="onUpdateInvoice" />
    </Dialog>
    <Dialog
      header="Hapus item dari daftar pembayaran ?"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Item dengan invoice <strong>{{ invoice.pi_no }}</strong> akan dihapus
          dari daftar pembayaran. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteItem"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import GridPembayaranHutangDetail from '@/components/pembelian/GridPembayaranHutangDetail'
import FormListPembayaranHutang from '@/components/pembelian/FormListPembayaranHutang'
import FormEditPembayaran from '@/components/pembelian/FormEditPembayaran'
import SupplierService from '@/services/SupplierService'
import DaftarKasService from '@/services/DaftarKasService'
import KontraService from '@/services/KontraPembelianService'
import ReturService from '@/services/ReturPembelianService'
import InvoiceService from '@/services/PurchaseInvoiceService'
import HutangService from '@/services/HutangService'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import Hotkey from '@/components/Hotkey'
import errorHandler from '@/helpers/error-handler'
import dayjs from 'dayjs'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormListPembayaranHutang,
    GridPembayaranHutangDetail,
    FormEditPembayaran,
    Hotkey,
  },
  data() {
    return {
      pembayaranService: null,
      kontraService: null,
      returService: null,
      invoiceService: null,
      supplierService: null,
      submitted: false,
      isLoading: false,
      isLoadingSaveHeader: false,
      isLoadingInvoice: false,
      //isTglBayar: false,
      pembayaran: 'tunai',
      form: {
        id: 0,
        pp_at: new Date(),
        pp_no: null,
        supplier: null,
        pembayaran: 'tunai',
        cn: 0,
        dn: 0,
        materai: 0,
        giro_no: '-',
        giro_at: new Date(),
        giro_status: null,
        giro_status_at: null,
        giro_keterangan: null,
        //settle_at: null,
        detail: [],
      },
      deleted: [],
      acSupplier: [],
      list_kontra: [],
      list_retur: [],
      list_invoice: [],
      list_giro_status: [],
      daftar_kas: [],
      dialogAdd: false,
      dialogEdit: false,
      dialogHapus: false,
      formEdit: {},
      invoice: null,
      supplierTop: 0,
      jumlah: 0,
    }
  },
  created() {
    this.supplierService = new SupplierService()
    this.pembayaranService = new HutangService()
    this.kontraService = new KontraService()
    this.returService = new ReturService()
    this.invoiceService = new InvoiceService()
  },
  computed: {
    list_kb_no() {
      return this.form.detail.map((x) => x.kb_no)
    },
    list_invoice_no() {
      return this.form.detail
        .filter((x) => x.pi_no && !x.pr_no)
        .map((x) => x.pi_no)
    },
    list_retur_no() {
      return this.form.detail.map((x) => x.pr_no)
    },
    list_daftar_kas() {
      return this.daftar_kas.filter(
        (el) => !el.rekening && el.nama.toLowerCase() !== 'kas kecil'
      )
    },
    list_kode_bank() {
      return this.daftar_kas.filter((el) => el.rekening)
    },
    disabledSave() {
      let isDisabled = true
      if (this.form.detail) {
        if (this.form.detail.length > 0) {
          let isRetur = false
          this.form.detail.forEach((el) => {
            let type = el.type ? el.type : 0
            if (el.type) type = el.type
            isRetur = type === 3
          })
          if (isRetur) {
            this.form.detail.forEach((el) => {
              let type = el.type ? el.type : 0
              if (type !== 3) {
                isDisabled = false
              }
            })
          } else {
            isDisabled = false
          }
        }
      }
      return isDisabled
      //return false
    },
    jumlahHutang() {
      return this.form.detail.reduce(function (total, item) {
        let type = item.type ? item.type : 0
        let jumlah = item.jumlah
        if (type === 3) {
          jumlah = jumlah * -1
        }
        return total + jumlah
      }, 0)
    },
    jumlahDibayar() {
      return this.form.detail.reduce(function (total, item) {
        //let type = item.type ? item.type : 0
        let jumlah = item.jumlah_dibayar
        /*
        if (type === 3) {
          jumlah = item.jumlah - item.diskon
        }
        */
        return total + jumlah
      }, 0)
    },
    jumlahRetur() {
      return this.form.detail.reduce(function (total, item) {
        let jumlah = 0
        if (item.type === 3) {
          jumlah = item.jumlah
        }
        return total + jumlah
      }, 0)
    },
  },
  mounted() {
    const self = this
    Promise.all([this.getDaftarKas()]).then(function (result) {
      self.daftar_kas = result[0]
      self.form.kas_id = result[0].filter(
        (el) => !el.rekening && el.nama.toLowerCase() !== 'kas kecil'
      )[0].id
    })

    this.list_giro_status = [
      { value: 'outstand', label: 'OUTSTAND', disabled: this.id > 0 },
      { value: 'cair', label: 'CAIR', disabled: false },
      { value: 'batal', label: 'BATAL', disabled: false },
    ]

    if (this.id > 0) {
      this.isLoading = true
      this.pembayaranService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.pp_at = this.form.pp_at ? new Date(this.form.pp_at) : null
            this.form.giro_at = this.form.giro_at
              ? new Date(this.form.giro_at)
              : null
            /*
            this.form.settle_at = this.form.settle_at
              ? new Date(this.form.settle_at)
              : null
            */
            this.form.giro_status_at = this.form.giro_status_at
              ? new Date(this.form.giro_status_at)
              : null
            //this.isTglBayar = this.form.settle_at != null
            this.form.supplier = res.data.data.supplier
            this.form.alamat = res.data.data.supplier.alamat
            this.form.kota = res.data.data.supplier.kota
            this.supplierTop = this.form.supplier.top
            if (res.data.data.pembayaran === 'tunai') {
              this.form.kas_id = res.data.data.kas.id
            } else {
              this.form.bank_id = res.data.data.kas.id
            }
            if (this.form.detail) {
              let detail = []
              this.form.detail.forEach((el, index) => {
                //el.jumlah_dibayar = el.jumlah
                if (el.prd_id > 0) {
                  el.type = 3
                }
                el.row_id = index + 1

                if (el.type === 3) {
                  if (detail.length === 0) {
                    detail.push(el)
                  } else {
                    let isExist = false
                    for (let index = 0; index < detail.length; index++) {
                      if (detail[index].pr_no === el.pr_no) {
                        detail[index].jumlah = detail[index].jumlah + el.jumlah
                        detail[index].jumlah_saldo =
                          detail[index].jumlah_saldo + el.jumlah_saldo
                        detail[index].jumlah_dibayar =
                          detail[index].jumlah_dibayar + el.jumlah_dibayar

                        let item = Object.assign({}, el)
                        detail[index].obat.push(item)
                        isExist = true
                        break
                      }
                    }

                    if (!isExist) {
                      let item = Object.assign({}, el)
                      el.obat = []
                      el.obat.push(item)
                      detail.push(el)
                    }
                  }
                } else {
                  detail.push(el)
                }
              })

              this.form.detail = detail
            }
            this.pembayaran = this.form.pembayaran
          }
        })
        .catch((err) => {
          errorHandler(err, 'Kontra bon', this)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  methods: {
    async getDaftarKas() {
      const kasService = new DaftarKasService()
      return await kasService
        .get()
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Daftar kas', this)
        })
    },
    async add() {
      if (!this.form.supplier) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Kontra bon',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      const self = this
      this.list_kontra = await this.getKontraList()
      this.list_invoice = await this.getInvoiceList()
      this.list_retur = await this.getReturList()
      if (
        this.list_kontra.length > 0 ||
        this.list_retur.length > 0 ||
        this.list_invoice.length > 0
      ) {
        self.dialogAdd = true
        self.isLoadingInvoice = false
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Kontra Bon/Retur',
          detail: `Kontra Bon/Retur untuk supplier ${this.form.supplier.nama} tidak ditemukan.`,
          life: 3000,
        })
        self.dialogAdd = false
        self.isLoadingInvoice = false
      }
    },
    save(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }

      let form = {}
      let detail = []

      this.form.detail.forEach((el) => {
        //Retur
        if (el.type === 3) {
          el.obat.forEach((obat) => {
            let data = {
              diskon: obat.diskon,
              jumlah_dibayar: obat.jumlah_dibayar,
              keterangan: !obat.keterangan ? '' : obat.keterangan,
            }
            if (obat.kb_no && obat.id === 0) {
              data.kbd_id = obat.kbd_id
            }
            if (obat.pr_no && obat.id === 0) {
              data.prd_id = obat.prd_id
            }
            if (obat.pi_no && !obat.kb_no && !obat.pr_no && obat.id === 0) {
              data.pi_id = obat.pi_id
            }

            if (obat.id > 0) {
              data.id = obat.id
            } else if (obat.id === 0 && this.form.id > 0) {
              data.id = 0
            }

            // jika sudah lunas (jumlah_saldo = 0), item jangan dikirim
            // retur tidak bisa di edit, kecuali insert baru
            //if (obat.jumlah_saldo > 0 || (obat.pr_no && obat.id === 0)) {
            detail.push(data)
            //}
          })
        } else {
          let data = {
            diskon: el.diskon,
            jumlah_dibayar: el.jumlah_dibayar,
            keterangan: !el.keterangan ? '' : el.keterangan,
          }
          if (el.kb_no && el.id === 0) {
            data.kbd_id = el.kbd_id
          }
          if (el.pr_no && el.id === 0) {
            data.prd_id = el.prd_id
          }
          if (el.pi_no && !el.kb_no && !el.pr_no && el.id === 0) {
            data.pi_id = el.pi_id
          }

          if (el.id > 0) {
            data.id = el.id
          } else if (el.id === 0 && this.form.id > 0) {
            data.id = 0
          }

          // jika sudah lunas (jumlah_saldo = 0), item jangan dikirim
          // retur tidak bisa di edit, kecuali insert baru
          //if (el.jumlah_saldo > 0 || (el.pr_no && el.id === 0)) {
          detail.push(data)
          //}
        }
      })

      if (this.form.pp_no) {
        form.pp_no = this.form.pp_no
      }

      form.pp_at = this.form.pp_at
        ? dayjs(this.form.pp_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.supplier_id = this.form.supplier.id
      form.detail = detail
      form.pembayaran = this.form.pembayaran
      if (this.form.pembayaran === 'tunai') {
        form.kas_id = this.form.kas_id
      } else {
        form.kas_id = this.form.bank_id
      }
      form.cn = this.form.cn
      form.dn = this.form.dn
      form.materai = this.form.materai
      //form.settle_at = null

      if (!form.cn) form.cn = 0
      if (!form.dn) form.dn = 0
      if (!form.materai) form.materai = 0

      if (this.form.pembayaran === 'giro' && this.form.giro_no) {
        form.giro_no = this.form.giro_no
        form.giro_at = this.form.giro_at
          ? dayjs(this.form.giro_at).format('YYYY-MM-DD HH:mm:ss')
          : null
        form.giro_status = this.form.giro_status
        form.giro_status_at = this.form.giro_status_at
        form.giro_keterangan = this.form.giro_keterangan
        /*
        form.settle_at = this.form.settle_at
        if (!this.isTglBayar) {
          form.settle_at = null
        }
        if (form.settle_at) {
          form.settle_at = dayjs(form.settle_at).format('YYYY-MM-DD HH:mm:ss')
        }
        */
      }
      if (form.detail.length > 0) {
        if (this.form.id === 0) {
          this.isLoadingSaveHeader = true
          this.pembayaranService
            .add(form)
            .then((res) => {
              if (res.data.status === 200) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Pembayaran Hutang',
                  detail: 'Data berhasil disimpan',
                  life: 3000,
                })
                this.$router.push({ name: 'PembayaranHutang' })
              }
            })
            .catch((err) => {
              errorHandler(err, 'Pembayaran Hutang', this)
            })
            .finally(() => (this.isLoadingSaveHeader = false))
        } else {
          this.isLoadingSaveHeader = true
          form.deleted = this.deleted
          this.pembayaranService
            .update(this.form.id, form)
            .then((res) => {
              if (res.data.status === 200) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Pembayaran Hutang',
                  detail: 'Data berhasil disimpan.',
                  life: 3000,
                })
              }
            })
            .catch((err) => {
              errorHandler(err, 'Pembayaran Hutang', this)
            })
            .finally(() => {
              this.deleted = []
              this.isLoadingSaveHeader = false
              this.refreshDetail()
            })
        }
      }
    },
    refreshDetail() {
      if (this.form.id > 0) {
        this.pembayaranService.get(`/${this.form.id}`).then((res) => {
          if (res.data.status === 200) {
            this.form.detail = res.data.data.detail
            if (this.form.detail) {
              let detail = []
              this.form.detail.forEach((el, index) => {
                //el.jumlah_dibayar = el.jumlah
                if (el.prd_id > 0) {
                  el.type = 3
                }
                el.row_id = index + 1

                if (el.type === 3) {
                  if (detail.length === 0) {
                    detail.push(el)
                  } else {
                    let isExist = false
                    for (let index = 0; index < detail.length; index++) {
                      if (detail[index].pr_no === el.pr_no) {
                        detail[index].jumlah = detail[index].jumlah + el.jumlah
                        detail[index].jumlah_saldo =
                          detail[index].jumlah_saldo + el.jumlah_saldo
                        detail[index].jumlah_dibayar =
                          detail[index].jumlah_dibayar + el.jumlah_dibayar

                        let item = Object.assign({}, el)
                        detail[index].obat.push(item)
                        isExist = true
                        break
                      }
                    }

                    if (!isExist) {
                      let item = Object.assign({}, el)
                      el.obat = []
                      el.obat.push(item)
                      detail.push(el)
                    }
                  }
                } else {
                  detail.push(el)
                }
              })
              this.form.detail = detail
            }
          }
        })
      }
    },
    selectSupplier(e) {
      this.form.alamat = e.value.alamat
      this.form.kota = e.value.kota
      this.supplierTop = e.value.top
    },
    getTanggalKembali(tanggal) {
      const top = this.supplierTop
      const quotient = Math.floor(top / 30)
      const remainder = top % 30
      const result = dayjs(tanggal.add(quotient, 'month').add(remainder, 'day'))
      return new Date(result.format(`YYYY/MM/DD`))
    },
    searchSupplier(e) {
      this.supplierService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acSupplier = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
    },
    async onEditInvoice(item) {
      this.formEdit = Object.assign({}, item)
      this.formEdit.item_at = this.formEdit.item_at
        ? new Date(this.formEdit.item_at)
        : null
      this.formEdit.jumlah = Math.round(this.formEdit.jumlah)
      this.formEdit.jumlah_dibayar = Math.round(this.formEdit.jumlah_dibayar)
      this.dialogEdit = true
    },
    onConfirmDeletion(item) {
      this.invoice = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeleteItem() {
      if (this.invoice.pr_no) {
        if (this.invoice.pr_no !== '') {
          //delete all child detail
          this.form.detail.forEach((element) => {
            if (element.pr_no === this.invoice.pr_no) {
              element.obat.forEach((obat) => {
                this.deleted.push(obat.id)
              })
            }
          })

          //delete detail
          this.form.detail = this.form.detail.filter(
            (element) => element.pr_no !== this.invoice.pr_no
          )

          this.form.detail.forEach((el, index) => {
            el.row_id = index + 1
          })
        }
      } else {
        if (this.invoice.id && this.invoice.id > 0) {
          this.deleted.push(this.invoice.id)
        }
        const index = this.form.detail.findIndex(
          (el) =>
            el.id === this.invoice.id &&
            el.kb_no === this.invoice.kb_no &&
            el.pi_no === this.invoice.pi_no
        )
        this.form.detail.splice(index, 1)

        this.form.detail.forEach((el, index) => {
          el.row_id = index + 1
        })
      }

      this.invoice = {}
      this.dialogHapus = false
      this.setJumlahHutang()
    },
    onSaveDetail(item) {
      item.forEach((el, index) => {
        const kembali_at = el.kembali_at
        const pi_at = el.pi_at
        if (kembali_at) {
          if (this.form.id === 0) {
            this.form.giro_at = new Date(kembali_at)
          }
        }
        if (pi_at) {
          if (this.form.id === 0) {
            this.form.giro_at = this.getTanggalKembali(dayjs(pi_at))
          }
        }
        if (el.jumlah_saldo > 0) {
          el.jumlah = el.jumlah_saldo
        }
        el.jumlah_dibayar = el.jumlah
        el.jumlah_dibayar_display = el.jumlah
        if (el.jumlah > 0) {
          this.form.detail.push(el)
        }
        el.row_id = index + 1
      })
      this.dialogAdd = false
      this.setJumlahHutang()
    },
    onUpdateInvoice(item) {
      /*
      const index = this.form.detail.findIndex(
        (el) => el.id === item.id && el.kb_no === item.kb_no
      )
      */
      const index = this.form.detail.findIndex(
        (el) => el.row_id === item.row_id
      )

      this.form.detail[index] = item
      this.dialogEdit = false
    },
    onCloseFormKontra() {
      this.dialogAdd = false
    },
    onUpdateMaterai(item) {
      this.form.materai = item
    },
    onUpdateCn(item) {
      this.form.cn = item
    },
    onUpdateDn(item) {
      this.form.dn = item
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
    onInputJumlah(e) {
      let value = e.value
      if (value > this.jumlahHutang) {
        value = this.jumlahHutang
      }
      this.jumlah = value
    },
    async getKontraList() {
      let result = []
      const self = this
      self.isLoadingInvoice = true
      const kontraService = new KontraService()
      const res = await (this.list_kontra = kontraService.get(
        `?filter[supplier_id]=${this.form.supplier.id}&filter[status_lunas]=0`
      ))
      if (res) {
        if (res.data.data) {
          if (res.data.data.length > 0) {
            let tmpResult = []
            tmpResult = res.data.data
            tmpResult.forEach((el) => {
              const detail = el.detail
              el.item_at = el.kb_at
              el.type = 2
              if (detail.length > 0) {
                detail.forEach((kb) => {
                  let item = Object.assign({}, el)
                  item.id = kb.id
                  item.kbd_id = kb.id
                  item.kb_no = el.kb_no
                  item.pi_no = kb.pi_no
                  item.pr_no = null
                  item.jumlah = kb.jumlah
                  item.diskon = 0
                  item.jumlah_dibayar = kb.jumlah
                  item.jumlah_saldo = kb.jumlah_saldo
                  item.kembali_at = el.kembali_at
                  if (kb.jumlah_saldo > 0) {
                    result.push(item)
                  }
                })
              }
            })
          }
        }
      }
      self.isLoadingInvoice = false
      return result
    },
    async getReturList() {
      let result = []
      const self = this
      self.isLoadingInvoice = true
      const returService = new ReturService()
      const res = await (this.list_retur = returService.get(
        `?filter[supplier_id]=${this.form.supplier.id}&filter[status_lunas]=0&filter[status]=sent`
      ))
      if (res) {
        if (res.data.data) {
          if (res.data.data.length > 0) {
            let tmpResult = []
            tmpResult = res.data.data
            tmpResult.forEach((el) => {
              if (el.obat && el.obat.length > 0) {
                el.type = 3
                result.push(el)
              }
            })
          }
        }
      }
      self.isLoadingInvoice = false
      return result
    },
    async getInvoiceList() {
      let result = []
      const self = this
      self.isLoadingInvoice = true
      const invoiceService = new InvoiceService()
      const res = await (this.list_invoice = invoiceService.get(
        `?filter[supplier_id]=${this.form.supplier.id}&filter[status]=done&filter[has_bon]=0&filter[status_lunas]=0`
      ))
      if (res) {
        if (res.data.data) {
          if (res.data.data.length > 0) {
            let tmpResult = []
            tmpResult = res.data.data
            tmpResult.forEach((el) => {
              el.type = 1
              result.push(el)
            })
          }
        }
      }
      self.isLoadingInvoice = false
      return result
    },
    setJumlahHutang() {
      this.jumlah = this.jumlahHutang
    },
    recalcDetail() {
      let value = 0
      if (this.jumlahRetur) {
        value = this.jumlah + this.jumlahRetur
      } else {
        value = this.jumlah
      }
      this.form.detail.forEach((item) => {
        let saldo = 0
        if (item.diskon) {
          saldo = item.jumlah - item.diskon
        } else {
          saldo = item.jumlah
        }
        if (item.type !== 3) {
          item.jumlah_dibayar = 0
          if (value >= saldo) {
            item.jumlah_dibayar = saldo
          } else {
            if (value > 0) {
              item.jumlah_dibayar = value
            }
          }
          value -= saldo
        } else {
          item.jumlah_dibayar = saldo
        }
      })
    },
  },
  watch: {
    jumlah() {
      this.recalcDetail()
    },
    pembayaran() {
      this.form.pembayaran = this.pembayaran
      if (this.form.pembayaran !== 'giro') {
        if (!this.form.giro_no) {
          this.form.giro_no = '-'
          this.form.giro_at = new Date()
        }
      } else {
        if (this.form.giro_no === '-') {
          this.form.giro_no = ''
          this.form.giro_at = null
        }
      }
    },
  },
  validations() {
    return {
      form: {
        pp_at: {
          required: helpers.withMessage('Tanggal harus diisi.', required),
        },
        supplier: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
        kas_id: {
          required: helpers.withMessage(
            'Kode Kas harus diisi.',
            requiredIf(this.form.pembayaran === 'tunai')
          ),
        },
        bank_id: {
          required: helpers.withMessage(
            'Kode Bank harus diisi.',
            requiredIf(this.form.pembayaran === 'transfer')
          ),
        },
        giro_no: {
          required: helpers.withMessage(
            'No. Giro harus diisi.',
            requiredIf(this.form.pembayaran === 'giro')
          ),
        },
        giro_at: {
          required: helpers.withMessage(
            'Tanggal jatuh tempo harus diisi.',
            requiredIf(this.form.pembayaran === 'giro')
          ),
        },
      },
    }
  },
}
</script>
