<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12">
          <label
            :class="{
              'p-error': v$.form.obat.$invalid && submitted,
            }"
            >Obat *</label
          >
          <AutoComplete
            v-model="form.obat"
            :min-length="2"
            :suggestions="acObat"
            :class="{
              'p-invalid': v$.form.obat.$invalid && submitted,
            }"
            field="nama"
            class="w-full"
            input-class="w-full"
            autofocus
            @complete="searchObat($event)"
            @item-select="selectObat"
          >
            <template #item="slotProps">
              <div v-if="slotProps.item.satuan[0] != null">
                {{ slotProps.item.kode }} - {{ slotProps.item.nama }}<br />
                Stock tersedia:
                {{ slotProps.item.stock_available }}<br />
                Isi per satuan:
                {{ slotProps.item.satuan[0].quantity }}<br />
              </div>
              <div v-else>
                {{ slotProps.item.kode }} - {{ slotProps.item.nama }}<br />
                Stock tersedia:
                {{ slotProps.item.stock_available }}<br />
                Isi per satuan: 1<br />
              </div>
            </template>
          </AutoComplete>
          <Message v-if="showWarning" severity="error" :closable="false"
            >Harga obat belum diset!</Message
          >
          <small
            v-if="
              (v$.form.obat.$invalid && submitted) ||
              v$.form.obat.$pending.$response
            "
            class="p-error"
            >{{ v$.form.obat.hasKode.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-3">
          <label
            :class="{
              'p-error': v$.form.quantity.$invalid && submitted,
            }"
            >Qty *</label
          >
          <InputText
            v-model="form.quantity"
            type="number"
            :class="{
              'p-invalid': v$.form.quantity.$invalid && submitted,
            }"
            :min="1"
            class="w-full"
            input-class="w-full"
            @input="updateKeterangan"
          />
          <small
            v-if="
              (v$.form.quantity.$invalid && submitted) ||
              v$.form.quantity.$pending.$response
            "
            class="p-error"
            >{{ v$.form.quantity.required.$message }}</small
          >
          <span v-if="v$.form.quantity.$error && submitted">
            <span
              v-for="(error, index) of v$.form.quantity.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
        </div>
        <!--
        <div class="field col-12 md:col-4">
          <label>Keterangan</label>
          <InputText
            v-model="form.keterangan_qty"
            type="text"
            class="w-full"
            onfocus="this.select();"
          />
        </div>
        -->
        <div class="field col-12 md:col-5">
          <label>Satuan</label>
          <InputText v-model="form.satuan" class="w-full" disabled />
        </div>
        <div class="field col-12">
          <label>Keterangan</label>
          <InputText
            v-model="form.keterangan"
            type="text"
            class="w-full"
            onfocus="this.select();"
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :disabled="showWarning"
          label="Tambahkan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, minValue } from '@vuelidate/validators'
import ObatService from '@/services/ObatService'
import errorHandler from '@/helpers/error-handler'
import { isObject } from '@/helpers'
import angkaTerbilang from '@/helpers/terbilang'

const hasKode = (value) => isObject(value, 'kode')

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    satuan: {
      type: Object,
      default() {
        return {}
      },
    },
    golongan: {
      type: String,
      default: '',
    },
  },
  created() {
    this.obatService = new ObatService()
  },
  data() {
    return {
      submitted: false,
      acObat: null,
      acSupplier: null,
      form: this.item,
      satuan_terkecil: this.satuan,
      showWarning: false,
    }
  },
  methods: {
    searchObat(e) {
      // &filter[status]=aktif dihapus per req tri 12/20/22
      this.obatService
        .get(`?filter[nama]=${e.query}&filter[golongan]=${this.golongan}`)
        .then((res) => {
          this.acObat = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Obat', this)
        })
    },
    selectObat(e) {
      this.form.kode = e.value.kode
      this.form.satuan = e.value.satuan_tkcl.nama
      this.satuan_terkecil = e.value.satuan_tkcl
      //this.showWarning = e.value.margin.harga === 0
    },
    updateKeterangan() {
      this.form.keterangan = this.form.quantity
        ? angkaTerbilang(this.form.quantity).toUpperCase()
        : ''
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const form = Object.assign({}, this.form)
      form.obat_id = this.form.obat.id
      form.nama_obat = this.form.obat.nama
      form.satuan_id = this.satuan_terkecil.id
      form.satuan = this.satuan_terkecil.nama

      delete form.obat

      this.$emit('save', form)

      this.form = {
        id: 0,
        obat: { kode: '', nama: '' },
        nama_obat: '',
        obat_id: 0,
        satuan_id: 0,
        satuan: '',
        quantity: 1,
        keterangan: 'SATU',
      }
    },
  },
  validations() {
    return {
      form: {
        obat: {
          hasKode: helpers.withMessage('Obat harus diisi.', hasKode),
        },
        quantity: {
          required: helpers.withMessage('Jumlah harus diisi.', required),
          minValue: helpers.withMessage('Jumlah minimal 1.', minValue(1)),
        },
        satuan: {
          required: helpers.withMessage('Satuan harus diisi.', required),
        },
      },
    }
  },
}
</script>
