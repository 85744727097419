<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_po"
          :globalFilterFields="['inv_no', 'nama_obat']"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter obat"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column field="inv_no" header="No Invoice" style="min-width: 11rem" />
          <Column field="inv_at" header="Tgl Invoice" style="min-width: 5rem">
            <template #body="{ data }">
              {{ formatDate(data.inv_at) }}
            </template>
          </Column>
          <Column field="nama_obat" header="Obat" style="min-width: 15rem" />
          <Column field="harga" header="Harga" style="min-width: 3rem">
            <template #body="{ data }">
              {{ formatCurrency(data.harga) }}
            </template>
          </Column>
          <Column field="quantity" header="QTY" style="min-width: 3rem">
            <template #body="{ data }">
              {{ formatNumber(data.quantity) }}
            </template>
          </Column>
          <Column field="satuan" header="Satuan" />
          <!-- <Column field="jenis_harga_description" header="Jenis harga" /> -->
          <Column field="pph" header="PPH" />
          <Column field="ppn" header="PPN" />
          <Column field="keterangan" header="Keterangan" />
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        class="p-button-text mr-2"
        @click="$emit('close')"
      />
      <Button
        :disabled="disabledSave"
        label="Tambahkan"
        icon="pi pi-check"
        @click="add"
      />
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatNumber, formatDate } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    purchaseInvoice: {
      type: Array,
      default() {
        return []
      },
    },
    excludeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_obat: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      disabledSave: true,
    }
  },
  watch: {
    selected(val) {
      if (val && val.length > 0) {
        this.disabledSave = false
      } else {
        this.disabledSave = true
      }
    },
  },
  computed: {
    list_po() {
      let items = []
      this.purchaseInvoice.forEach((po) => {
        po.obat.forEach((el) => {
          el.pid_id = el.id
          el.id = 0
          el.inv_no = po.inv_no
          el.inv_at = po.inv_at
          el.pi_no = po.pi_no
          el.pi_at = po.pi_at
          el.keterangan = ''
          el.pph = po.pph
          el.ppn = po.ppn
          el.jenis_harga_description = po.jenis_harga_description
          items.push(el)
        })
      })
      const result = items.slice()
      return result.filter((item) => {
        return !this.excludeList.some(
          (el) =>
            el.obat_id === item.obat_id &&
            el.inv_no === item.inv_no &&
            el.po_no === item.po_no &&
            el.batch_id === item.batch_id
        )
      })
    },
  },
  methods: {
    add() {
      this.$emit('save', this.selected)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      value = value ? value : 0
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      value = value ? value : 0
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
