<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-12">
          <label>Tgl Bukti</label>
          <Calendar
            v-model="form.pi_at"
            dateFormat="dd/mm/yy"
            :class="{
              'p-invalid': v$.form.pi_at.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.pi_at.$invalid && submitted) ||
              v$.form.pi_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.pi_at.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>No Bukti</label>
          <InputText
            v-model="form.pi_no"
            class="w-full"
            :class="{
              'p-invalid': v$.form.pi_no.$invalid && submitted,
            }"
          />
          <small
            v-if="
              (v$.form.pi_no.$invalid && submitted) ||
              v$.form.pi_no.$pending.$response
            "
            class="p-error"
            >{{ v$.form.pi_no.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Tgl Faktur</label>
          <Calendar
            v-model="form.inv_at"
            dateFormat="dd/mm/yy"
            :class="{
              'p-invalid': v$.form.inv_at.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.inv_at.$invalid && submitted) ||
              v$.form.inv_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.inv_at.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>No Faktur</label>
          <InputText
            v-model="form.inv_no"
            class="w-full"
            :class="{
              'p-invalid': v$.form.inv_no.$invalid && submitted,
            }"
          />
          <small
            v-if="
              (v$.form.inv_no.$invalid && submitted) ||
              v$.form.inv_no.$pending.$response
            "
            class="p-error"
            >{{ v$.form.inv_no.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Tgl Jatuh Tempo</label>
          <Calendar
            v-model="form.top_at"
            dateFormat="dd/mm/yy"
            :class="{
              'p-invalid': v$.form.top_at.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.top_at.$invalid && submitted) ||
              v$.form.top_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.top_at.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Jumlah</label>
          <InputNumber
            v-model="form.jumlah"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
          />

          <small
            v-if="
              (v$.form.jumlah.$invalid && submitted) ||
              v$.form.jumlah.$pending.$response
            "
            class="p-error"
            >{{ v$.form.jumlah.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  created() {},
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    onCloseForm() {
      this.$emit('close')
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        pi_no: {
          required: helpers.withMessage('No Faktur harus diisi.', required),
        },
        pi_at: {
          required: helpers.withMessage(
            'Tanggal Faktur harus diisi.',
            required
          ),
        },
        inv_no: {
          required: helpers.withMessage('No Bukti harus diisi.', required),
        },
        inv_at: {
          required: helpers.withMessage('Tgl Terima harus diisi.', required),
        },
        top_at: {
          required: helpers.withMessage(
            'Tgl Jatuh Tempo harus diisi.',
            required
          ),
        },
        jumlah: {
          required: helpers.withMessage('Jumlah harus diisi.', required),
        },
      },
    }
  },
}
</script>
