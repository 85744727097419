<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Piutang"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'SaldoAwalPiutang' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-12">
                <label>Pelanggan</label>
                <Dropdown
                  v-model="form.pelanggan"
                  :filter="true"
                  :options="listPelanggan"
                  class="w-full"
                  input-class="w-full"
                  optionValue="id"
                  :optionLabel="pelangganLabel"
                  placeholder="Pilih Pelanggan"
                  :disabled="form.saldo > 0"
                />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-3">
            <div class="formgrid grid">
              <div class="field col-12">
                <label>Saldo</label>
                <InputNumber
                  v-model="form.saldo"
                  :minFractionDigits="0"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <grid-piutang-detail
          :items="form.invoice"
          :loading="isLoading"
          :show-button="true"
          @edit="onEditData"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>

    <Dialog
      header="Tambah Saldo Awal Piutang"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      maximizable
    >
      <form-piutang-saldo
        :item="formData"
        :loading="isLoadingSave"
        @close="this.dialogAdd = false"
        @save="onSaveAdd"
      />
    </Dialog>
    <Dialog
      header="Hapus item ?"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
    >
      <div>
        <span
          >Item dengan no bukti <strong>{{ formData }}</strong> akan dihapus
          dari daftar. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogDelete = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
    <Dialog
      header="Edit Saldo Awal Piutang"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
    >
      <form-piutang-saldo
        :loading="isLoadingSave"
        :item="formData"
        @save="onSaveEdit"
        @close="dialogEdit = false"
      />
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import PelangganService from '@/services/PelangganService'
import SaldoAwalPiutangService from '@/services/SaldoAwalPiutangService'
import FormPiutangSaldo from '@/components/keuangan/saldo/FormPiutangSaldo'
import GridPiutangDetail from '@/components/keuangan/saldo/GridPiutangDetail'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormPiutangSaldo,
    GridPiutangDetail,
    Hotkey,
  },
  created() {
    this.dataService = new SaldoAwalPiutangService()
    this.pelangganService = new PelangganService()
  },
  data() {
    return {
      submitted: false,
      dialogAdd: false,
      dialogDelete: false,
      dialogEdit: false,
      isLoading: false,
      isLoadingSave: false,
      listPelanggan: [],
      inv: null,
      form: {
        id: 0,
        pelanggan_id: 0,
        salesman_id: 0,
        nama: null,
        saldo: 0,
        invoice: [],
      },
      formData: {
        id: 0,
        pelanggan_id: 0,
        salesman_id: 0,
        si_no: null,
        si_at: new Date(),
        top_at: new Date(),
        dpp: 0,
        ppn: 0,
        jumlah: 0,
      },
    }
  },
  mounted() {
    this.onLoadPelanggan()
    if (this.id > 0) {
      this.isLoading = true
      this.onLoadGridData()
    }
  },
  computed: {
    pelangganLabel() {
      return (listPelanggan) => listPelanggan.kode + ' - ' + listPelanggan.nama
    },
  },
  methods: {
    onLoadPelanggan() {
      this.pelangganService
        .get()
        .then((res) => {
          this.listPelanggan = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    onLoadGridData() {
      this.dataService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.pelanggan_id = res.data.data.pelanggan_id
            this.form.pelanggan = res.data.data.pelanggan_id
            this.form.saldo = res.data.data.saldo
            let invoice = res.data.data.invoice
            this.form.invoice = invoice
          }
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadGridDetail(e) {
      this.dataService
        .get(`/${e}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.pelanggan_id = res.data.data.pelanggan_id
            this.form.pelanggan = res.data.data.pelanggan_id
            this.form.salesman = res.data.data.salesman_id
            this.form.saldo = res.data.data.saldo
            let invoice = res.data.data.invoice
            this.form.invoice = invoice
          }
        })
        .finally(() => (this.isLoading = false))
    },
    add() {
      if (!this.form.pelanggan) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Saldo Awal Piutang',
          detail: 'Pelanggan harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }
      this.formData = {}
      this.dialogAdd = true
    },
    onShowDialog(data) {
      this.form = Object.assign({}, data)
      this.dialogSave = true
    },
    onSaveAdd(data) {
      let item = {}
      item.pelanggan_id = this.form.pelanggan
      item.salesman_id = data.salesman ? data.salesman : 0
      item.si_no = data.si_no
      item.si_at = dayjs(data.si_at).format('YYYY-MM-DD')
      item.inv_no = data.inv_no
      item.inv_at = dayjs(data.inv_at).format('YYYY-MM-DD')
      item.top_at = dayjs(data.top_at).format('YYYY-MM-DD')
      item.dpp = data.dpp
      item.ppn = data.ppn
      item.jumlah = data.dpp + +data.ppn

      this.isLoadingSave = true
      this.dataService
        .add(item)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Saldo Awal Piutang',
              detail: 'Saldo Awal Piutang berhasil ditambahkan.',
              life: 3000,
            })
            this.onLoadGridDetail(item.pelanggan_id)
            this.dialogAdd = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Saldo Awal Piutang', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onEditData(item) {
      this.formData = Object.assign({}, item)
      this.formData.salesman = item.salesman_id
      this.formData.si_at = this.formData.si_at
        ? new Date(this.formData.si_at)
        : null
      this.formData.top_at = this.formData.top_at
        ? new Date(this.formData.top_at)
        : null
      this.dialogEdit = true
    },
    onSaveEdit(data) {
      console.log(data)
      let item = {}
      item.id = data.id
      item.salesman_id = data.salesman ? data.salesman : 0
      item.si_no = data.si_no
      item.si_at = dayjs(data.si_at).format('YYYY-MM-DD')
      item.top_at = dayjs(data.top_at).format('YYYY-MM-DD')
      item.dpp = data.dpp
      item.ppn = data.ppn
      item.jumlah = data.dpp + +data.ppn

      this.isLoadingSave = true
      this.dataService
        .update(item, data.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Saldo Awal Piutang',
              detail: 'Saldo Awal Piutang berhasil diperbarui.',
              life: 3000,
            })
            this.onLoadGridDetail(data.pelanggan_id)
            this.dialogEdit = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Saldo Awal Piutang', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onConfirmDeletion(item) {
      this.formData = Object.assign({}, item)
      this.dialogDelete = true
    },
    onDeleteData() {
      this.dataService
        .delete(this.formData.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Saldo Awal Piutang',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.onLoadGridDetail(this.formData.pelanggan_id)
          }
        })
        .catch((err) => {
          errorHandler(err, 'Saldo Awal Piutang', this)
        })
      this.dialogDelete = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
}
</script>
