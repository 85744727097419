import http from '../clients/Axios'

export default class ReturPembelianService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/purchase/return/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    return await http.get(`/purchase/return${param}`)
  }

  async add(form) {
    return await http.post('/purchase/return', form)
  }

  async update(form) {
    return await http.post(`/purchase/return/${form.id}`, form)
  }

  async delete(id) {
    return await http.delete(`/purchase/return/${id}`)
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/purchase/return/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async downloadNota(id) {
    return http({
      url: `/purchase/return/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
