<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span v-if="id > 0" class="pr-2 mr-2 font-bold text-2xl"
        >Saldo Awal Piutang - Edit</span
      >
      <span v-else class="pr-2 mr-2 font-bold text-2xl"
        >Saldo Awal Piutang - Tambah</span
      >
      <div>
        <i class="pi pi-home mr-2"></i>
        <span v-if="id > 0" class="font-small"
          >/ Keuangan / Master / Saldo Awal / Piutang - Edit</span
        >
        <span v-else class="font-small"
          >/ Keuangan / Master / Saldo Awal / Piutang - Tambah</span
        >
      </div>
    </div>
    <form-piutang :id="id" />
  </div>
</template>

<script>
import FormPiutang from '@/components/keuangan/saldo/FormPiutang'

export default {
  components: {
    FormPiutang,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
    }
  },
}
</script>
