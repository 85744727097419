<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Hutang"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'SaldoAwalHutang' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-12">
                <label>Supplier</label>
                <Dropdown
                  v-model="form.supplier"
                  :filter="true"
                  :options="listSupplier"
                  class="w-full"
                  input-class="w-full"
                  optionValue="id"
                  :optionLabel="supplierLabel"
                  placeholder="Pilih Supplier"
                  :disabled="form.saldo > 0"
                />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-3">
            <div class="formgrid grid">
              <div class="field col-12">
                <label>Saldo</label>
                <InputNumber
                  v-model="form.saldo"
                  :minFractionDigits="0"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <grid-hutang-detail
          :items="form.invoice"
          :loading="isLoading"
          :show-button="true"
          @edit="onEditData"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>

    <Dialog
      header="Tambah Saldo Awal Hutang"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      maximizable
    >
      <form-hutang-saldo
        :item="formData"
        :loading="isLoadingSave"
        @close="this.dialogAdd = false"
        @save="onSaveAdd"
      />
    </Dialog>
    <Dialog
      header="Hapus item ?"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
    >
      <div>
        <span
          >Item dengan no bukti <strong>{{ formData.pi_no }}</strong> akan
          dihapus dari daftar. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogDelete = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
    <Dialog
      header="Edit Saldo Awal Hutang"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
    >
      <form-hutang-saldo
        :loading="isLoadingSave"
        :item="formData"
        @save="onSaveEdit"
        @close="dialogEdit = false"
      />
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import SupplierService from '@/services/SupplierService'
import SaldoAwalHutangService from '@/services/SaldoAwalHutangService'
import FormHutangSaldo from '@/components/keuangan/saldo/FormHutangSaldo'
import GridHutangDetail from '@/components/keuangan/saldo/GridHutangDetail'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormHutangSaldo,
    GridHutangDetail,
    Hotkey,
  },
  created() {
    this.dataService = new SaldoAwalHutangService()
    this.supplierService = new SupplierService()
  },
  data() {
    return {
      submitted: false,
      dialogAdd: false,
      dialogDelete: false,
      dialogEdit: false,
      isLoading: false,
      isLoadingSave: false,
      listSupplier: [],
      inv: null,
      form: {
        id: 0,
        supplier_id: 0,
        nama: null,
        saldo: 0,
        invoice: [],
      },
      formData: {
        id: 0,
        supplier_id: 0,
        pi_no: null,
        pi_at: new Date(),
        inv_no: null,
        inv_at: new Date(),
        top_at: new Date(),
        jumlah: 0,
      },
    }
  },
  mounted() {
    this.onLoadSupplier()
    if (this.id > 0) {
      this.isLoading = true
      this.onLoadGridData()
    }
  },
  computed: {
    supplierLabel() {
      return (listSupplier) => listSupplier.kode + ' - ' + listSupplier.nama
    },
  },
  methods: {
    onLoadSupplier() {
      this.supplierService
        .get('?filter[status]=aktif')
        .then((res) => {
          this.listSupplier = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
    },
    onLoadGridData() {
      this.dataService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.supplier_id = res.data.data.supplier_id
            this.form.supplier = res.data.data.supplier_id
            this.form.saldo = res.data.data.saldo
            let invoice = res.data.data.invoice
            this.form.invoice = invoice
          }
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadGridDetail(e) {
      this.dataService
        .get(`/${e}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.supplier_id = res.data.data.supplier_id
            this.form.supplier = res.data.data.supplier_id
            this.form.saldo = res.data.data.saldo
            let invoice = res.data.data.invoice
            this.form.invoice = invoice
          }
        })
        .finally(() => (this.isLoading = false))
    },
    add() {
      if (!this.form.supplier) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Saldo Awal Hutang',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }
      this.formData = {}
      this.dialogAdd = true
    },
    onShowDialog(data) {
      this.form = Object.assign({}, data)
      this.dialogSave = true
    },
    onSaveAdd(data) {
      let item = {}
      item.supplier_id = this.form.supplier
      item.pi_no = data.pi_no
      item.pi_at = dayjs(data.pi_at).format('YYYY-MM-DD')
      item.inv_no = data.inv_no
      item.inv_at = dayjs(data.inv_at).format('YYYY-MM-DD')
      item.top_at = dayjs(data.top_at).format('YYYY-MM-DD')
      item.jumlah = data.jumlah

      this.isLoadingSave = true
      this.dataService
        .add(item)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Saldo Awal Hutang',
              detail: 'Saldo Awal Hutang berhasil ditambahkan.',
              life: 3000,
            })
            this.onLoadGridDetail(item.supplier_id)
            this.dialogAdd = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Saldo Awal Hutang', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onEditData(item) {
      this.formData = Object.assign({}, item)
      this.formData.pi_at = this.formData.pi_at
        ? new Date(this.formData.pi_at)
        : null
      this.formData.inv_at = this.formData.inv_at
        ? new Date(this.formData.inv_at)
        : null
      this.formData.top_at = this.formData.top_at
        ? new Date(this.formData.top_at)
        : null
      this.dialogEdit = true
    },
    onSaveEdit(data) {
      let item = {}
      item.id = data.id
      // item.supplier_id = data.supplier_id
      item.pi_no = data.pi_no
      item.pi_at = dayjs(data.pi_at).format('YYYY-MM-DD')
      item.inv_no = data.inv_no
      item.inv_at = dayjs(data.inv_at).format('YYYY-MM-DD')
      item.top_at = dayjs(data.top_at).format('YYYY-MM-DD')
      item.jumlah = data.jumlah

      this.isLoadingSave = true
      this.dataService
        .update(item, data.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Saldo Awal Hutang',
              detail: 'Saldo Awal Hutang berhasil diperbarui.',
              life: 3000,
            })
            this.onLoadGridDetail(data.supplier_id)
            this.dialogEdit = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Saldo Awal Hutang', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onConfirmDeletion(item) {
      this.formData = Object.assign({}, item)
      this.dialogDelete = true
    },
    onDeleteData() {
      this.dataService
        .delete(this.formData.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Saldo Awal Hutang',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.onLoadGridDetail(this.formData.supplier_id)
          }
        })
        .catch((err) => {
          errorHandler(err, 'Saldo Awal Hutang', this)
        })
      this.dialogDelete = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
}
</script>
